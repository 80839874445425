.welcome__container {
    margin-top: 5rem;
    font-size: 40px;
    color: #f44336;
}
.welcome__description {
    font-size: 20px;
    color: black;
    width: 60rem;
    margin-right: 10rem;
    a {
        display: block;
        text-align: center;
        background-color: #f44336;
        margin-top: 2rem;
        border-radius: 10px;
        padding: 0.2rem 0rem;
        color: white;
        font-size: 30px;
    }
    a:hover {
        text-decoration: none;
        background-color: #922720;
        cursor: pointer;
    }
}
.welcome__title {
    margin-bottom: 4rem;
}
.welcome__section {
    img {
        width: 40rem;
        border-radius: 10px;
    }
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}


@media screen and (max-width: 1300px) {
    .welcome__title {
        font-size: 30px;
    }
    .welcome__description {
        width: 95%;
        margin: 0 auto;
        a {
            padding: 1 1rem;
            font-size: 25px;
        }
    }
    .welcome__section img {
        width: 80%;
        margin: 0 auto;
        margin-top: 5rem;
    }
}