.why__container {
  margin-bottom: 5rem;
  margin-top: 10rem;
  margin-left: 5%;
  margin-right: 5%;
}
.why__title {
  color: #f44336;
  font-size: 40px;
  text-align: left;
  margin: 0 10%;
  margin-bottom: 5rem;
}
.why__options {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}
.why__option {
  text-align: left;
  width: 30rem;
  margin-bottom: 5rem;
  i {
    font-size: 40px;
  }
}
.why__option__title {
  font-weight: bold;
  font-size: 25px;
  margin-bottom: 1rem;
}
.why__quote__title {
  color: #f44336;
  font-size: 40px;
  margin-top: 5rem;
  margin-bottom: -5rem;
}
.why__option {
  a {
    color: #f44336;
    text-decoration: none;
  }
}

@media screen and (max-width: 1300px) {
  .why__container {
    margin-top: 5rem;
    margin-left: 0%;
    margin-right: 0%;
  }
  .why__title {
    font-size: 30px;
    margin: 0 0%;
    margin-bottom: 5rem;
    text-align: center;
  }
  .why__options {
      padding-left: 1rem;
  }
  .why__quote__title {
    font-size: 30px;
  }
}
