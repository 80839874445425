.App {
  text-align: center;
  font-size: 15pt;
  // font-family: 'Cairo', sans-serif;
  // font-family: 'Viga', sans-serif;
  font-family: 'DM Sans', sans-serif;
}



