.prices__content {
    text-align: right;
    margin-right: 5%;
    margin-top: 20rem;
    padding-bottom: 18rem;
}
.prices__title {
    color: white;
    font-size: 50px;
    font-weight: bold;
}
.prices__description {
    margin-top: 1rem;
    color: #f44336;
    font-size: 30px;
    font-weight: bold;
}

@media screen and (max-width: 1500px) {
    .prices__title {
        font-size: 30px;
    }
    .prices__description {
        font-size: 20px;
    }
    .prices__content {
        text-align: center;
        margin: 10rem auto;
    }
}