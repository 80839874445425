.footer__container {
    background-color: #fdf3d9;
    color: black;
    margin-top: -10px;
    padding: 0;
    a {
        text-decoration: none;
        color: black;
        font-size: 20px;
        margin-right: 5rem;
    }
    a:hover {
        color: grey;
    }
}
.footer__menu {
    display: flex;
    flex-wrap: wrap;
    padding: 2rem 0;
    margin-bottom: 3rem;
    justify-content: center;
}
.footer__logo {
    img {
        width: 20rem;
    }
}
.footer__content {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;
    margin-bottom: 5rem;
}
.footer__about {
    max-width: 40%;
    text-align: right;
    a {
        text-align: right;
        margin: 0;
    }
}
.footer__info {
    font-size: 15px;
    background-color: #d1c9b5;
    padding: 1rem 0;
    a {
        font-size: 15px;
        color: red;
        text-align: center;
        margin: 0;
    }
}
.logo__description {
    font-size: 20px;
}

@media screen and (max-width: 680px) {
    .footer__menu {
        margin: 0 auto;
    }
    .footer__about {
        text-align: center;
        max-width: 100%;
        padding: 0 1rem;
        font-size: 15px;
        margin-top: 2rem;
    }
    .footer__logo {
        img {
            margin-right: 2rem;
            margin-bottom: 1rem;
        }
    }
    a.footer__link {
        margin-right: 0rem;
    }
    .footer__menu {
        justify-content: space-between;
        margin: 0 1rem;
        margin-bottom: 2rem;
    }
}

