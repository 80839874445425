.quote__container {
    margin: 10rem auto;
    margin-bottom: 5rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
}
.quote__title {
    font-size: 40px;
    margin-bottom: 3rem;
    color: #f44336;
    .quote__title__content {
        color: grey;
        font-size: 15px;
    }
}
.quote__warning {
    font-size: 15px;
    color: grey;
    text-align: right;
    margin-top: 1rem;
}
.quote__form {
    text-align: center;
    .input__container {
        display: block;
        width: 100%;
        margin-bottom: 1.5rem;
        color: #f44336;
        span {
            padding-right: 1rem;
        }
    }
    input, select {
        border-color: #f44336;
        border-radius: 5px;
        border: 1px solid #f44336;
        padding-left: 0.5rem;
        width: 85%;
    }
    button {
        background-color: #f44336;
        color: white;
        width: 100%;
        border: none;
        border-radius: 5px;
        padding: 0.2rem 0;
    }
    button:hover {
        background-color: #922720;
    }
    .input__container.phone {
        margin-bottom: 0.4rem;
    }
    .date__text {
        color: #f44336;
        font-size: 15px;
    }
}
.quote {
    // border: solid 1px #404fd6;
    padding: 7rem 3rem;
    padding-top: 5rem;
    border-radius: 10px;
    box-shadow: 0px 1px 4px 1px rgba(0,0,0,0.75);
    // max-width: 35%;
    width: 50rem;
}
.contacts__img {
    width: 30rem;
}
.contacts__links {
    font-size: 30px;
    text-align: left;
    div {
        margin-bottom: 1rem;
    }
    i {
        color: #f44336;
    }
}
iframe {
    width: 100%;
    border: none;
    height: 30rem;
    margin: 0;
    padding: 0;
}
.contacts__socials {
    font-size: 45px;
    color:#f44336;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    margin-top: 2rem;
    a {
        color: #f44336;
    }
    a:hover {
        transform: scale(1.2);
    }
}

.input__flex {
    display: flex;
    
    justify-content: center;
    margin: 0 auto;
    input {
        margin-right: 0rem;
        width: calc(42.5% - 0.5rem);
    }
    #first-name, #move-from {
        margin-right: 1rem;
    }
}

@media screen and (max-width: 1300px) {
    .quote__container {
        margin: 10rem auto;
    }
    .quote__title {
        font-size: 30px;
    }
    .contacts__links {
        font-size: 20px;
        margin-left: 5rem;
    }
    .quote {
        // border: solid 1px #404fd6;
        padding: 5rem 1rem;
        padding-top: 5rem;
        text-align: center;
        width: 95%;
    }
    .contacts__img {
        width: 95%;
        margin-top: 5rem;
    }
}
@media screen and (max-width: 680px) {
    .quote__form {
        select {
            width: 85%;
        }
    }
    .contacts__links {
        margin: 0 auto;
        width: 95%;
    }
}