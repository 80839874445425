.socials__container {
    align-items: center;
    flex-wrap: nowrap;
    display: flex;
    justify-content: space-around;
    padding: 1rem 5rem;
    margin-top: 6rem;
}
.socials {
    font-size: 25pt;
    i {
        margin-right: 1rem;
        color: #f44336;
    }
    i:hover {
        transform: scale(1.3);
    }
}
.phone {
    a {
        margin-right: 10rem;
        background-color: #f44336;
        color: white;
        padding: 0.2rem 1rem;
        border-radius: 8px;
    }
    a:hover {
        text-decoration: none;
        background-color: #922720;
    }
    a.quote__link {
        background-color: white;
        color: #f44336;
        border: 2px solid #f44336;
    }
    a.quote__link:hover {
        background-color: #922720;
        color: white;
    }
}

@media screen and (max-width: 1500px) {
    .phone {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        a {
            margin-bottom: 1rem;
        }
    }
    .socials {
        display: flex;
        flex-wrap: nowrap;
        flex-direction: column;
    }
}
@media screen and (max-width: 680px) {
    .socials__container {
        display: block;
        padding: 0;
        margin-top: 7rem;
        .phone {
            text-align: center;
            margin: 0;
            a {
                margin: 0.5rem 1rem;
            }
        }
        .socials {
            display: flex;
            flex-wrap: nowrap;
            flex-direction: row;
            justify-content: center;
        }
    }
}
