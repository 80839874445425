.privacy__container {
  margin-bottom: 10rem;
  span {
    color: #f44336;
  }
  a {
      color: #f44336;
  }
}
.privacy__text {
  margin-top: 5rem;
}
.privacy__box {
  margin-top: 5rem;
  text-align: left;
  margin: 0 auto;
  width: 70%;
  margin-bottom: 5rem;
}
.privacy__box__title {
    font-size: 30px;
    color: #f44336;
    margin-bottom: 2rem;
}

@media screen and (max-width: 680px) {
  .privacy__box {
    width: 95%;
  }
  .privacy__box__title {
    text-align: center;
  }
}
