.nav__container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  background-color: #fdf3d9;
  opacity: 0.9;
  font-weight: bold;
  color: #f44336;
  padding: 0.5rem 0;
  box-shadow: 0px 1px 8px 1px rgba(0, 0, 0, 0.75);
  position: fixed;
  top: 1.9rem;
  z-index: 5;
  width: 100%;
}
.header__covid {
  //background-color: #f44336;
  
  background: rgb(253,243,217);
  background: linear-gradient(90deg, rgba(253,243,217,0.9) 8%, rgba(222,23,23,1) 50%, rgba(253,243,217,0.9) 92%);

  color: white;
  position: fixed;
  z-index: 6;
  width: 100%;
  a {
    color: yellow;
  }
}
.nav__title {
  flex-grow: 3;
  text-align: left;
  padding-left: 5rem;
  font-size: 25pt;
  img {
    display: inline;
    width: 10rem;
    position: absolute;
    top: -0.8rem;
    left: -10rem;
    z-index: 1;
  }
  a {
    position: relative;
    margin-left: 10rem;
  }
}
.nav__links {
  display: flex;
  margin-right: 4rem;
  flex-wrap: wrap;
}
.nav__link {
  padding-left: 2.5rem;
  padding-right: 2.5rem;
  position: relative;
}
.nav__links a {
  color: black;
  text-decoration: none;
}
.fa-angle-down {
  vertical-align: -3px;
}
#services:hover ~ .services__sub {
  display: block;
}
#prices:hover ~ .services__sub {
  display: block;
}
.services__sub:hover {
  display: block;
}
.services__sub {
  font-size: 13pt;
  position: absolute;
  top: 1.5rem;
  left: -0.5rem;
  background-color: #fdf3d9;
  padding: 2rem 1rem;
  padding-bottom: 1rem;
  display: none;
  a {
    display: block;
    margin-bottom: 1rem;
  }
}
.nav__links a:hover {
  color: #f44336;
  text-decoration: none;
}
.nav__title a {
  color: white;
  text-decoration: none;
}
.nav__hamburger {
  display: none;
  input {
    display: none;
  }
}
.hamburger__content {
  width: 18rem;
  position: absolute;
  padding-top: 2rem;
  top: 4rem;
  right: 0rem;
  background-color: #fdf3d9;
  opacity: 0.9;
  display: none;
  font-size: 30px;
  border-radius: 0 0 0 10px;
  a, label {
    display: block;
    color: black;
    margin-bottom: 0.5rem;
  }
  i {
    font-size: 40px;
  }
}
#hamburger:checked ~ .hamburger__content {
  display: block;
}
#hamburger:checked ~ label {
  color: grey;
}


.services__nav__content {
  display: none;
  font-size: 15pt;
}
#services__mobile:checked ~ .services__nav__content {
  display: block;
}
#services__mobile:checked ~ label i.fa-angle-down {
  display: none;
}
#services__mobile:not(:checked) ~ label i.fa-angle-up {
  display: none;
}

.prices__nav__content {
  display: none;
  font-size: 15pt;
}
#prices__mobile:checked ~ .prices__nav__content {
  display: block;
}
#prices__mobile:checked ~ label i.fa-angle-down {
  display: none;
}
#prices__mobile:not(:checked) ~ label i.fa-angle-up {
  display: none;
}

@media screen and (max-width: 1300px) {
  .nav__links {
    display: none;
  }
  .nav__hamburger {
    display: block;
    margin-right: 5rem;
    font-size: 30px;
  }
  .fa-angle-down, .fa-angle-up {
    vertical-align: -7px;
  }
}
@media screen and (max-width: 680px) {
  .nav__title {
    padding-left: 0rem;
    span {
      display: none;
    }
  }
  .nav__hamburger {
    font-size: 40px;
    margin-right: 1rem;
  }
  .header__covid {
    font-size: 16px;
    background: #f44336;
  }
  .nav__container {
    top: 1.5rem;
  }
}

.nav__close {
  background-color: transparent;
  border: none;
}