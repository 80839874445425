
.about__content {
    background-color: white;
    margin: 20rem auto;
    width: 70%;
    text-align: center;
    margin-top: 15rem;
    padding: 5rem 5%;
    border-radius: 10px;

}
.about__title {
    font-size: 40px;
    font-weight: bold;
    color: #f44336;
    margin-bottom: 5rem;
}
.about__container {
    margin-bottom: 10rem;
}

@media screen and (max-width: 680px) {
    .about__content {
        width: 95%;
        margin: 10rem auto;
    }
    .about__title {
        font-size: 30px;
    }
}