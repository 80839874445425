.services__page__title {
    font-size: 50px;
    color: #fdf3d9;
    font-weight: bold;
}
.services__page__description {
    font-size: 40px;
    color: white;
}
.services__content {
    margin: 10rem 0;
}
.services__page__container {
    margin-bottom: 10rem;
}

@media screen and (max-width: 1500px) {
    .services__page__title {
        font-size: 40px;
    }
    .services__page__description {
        font-size: 30px;
    }
}