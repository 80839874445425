.image__container {
  img {
    width: 100%;
  }
  width: 100%;
  z-index: -1;
}
.test {
    min-height: 15rem;
}
.image__title {
    font-size: 50px;
    text-align: right;
    font-weight: bold;
    color: white;
    margin-bottom: 5rem;
    margin-left: 5rem;
    padding-right: 5%;
    .image__description {
        font-size: 35px;
        color: #f44336;
    }
}
.image__buttons {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    font-size: 40px;
    margin-bottom: 5rem;
    font-weight: bold;
}
.image__button {
    color: #f44336;
    background-color: white;
    border-radius: 15px;
    padding: 0 2rem;
    border: solid 3px #f44336;
    i {
        vertical-align: -3px;
    }
}
.image__button:hover {
    color: white;
    text-decoration: none;
    background-color: #f44336;
}

@media screen and (max-width: 1300px) {
    .image__title {
        text-align: center;
        padding-right: 0;
        margin: auto;
        margin-bottom: 5rem;
        font-size: 30px;
        .image__description {
            font-size: 25px;
        }
    }
    .image__button {
        margin-bottom: 1rem;
        width: 95%;
        font-size: 30px;
    }
}
