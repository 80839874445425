.carousel__container {
  width: 40rem;
  text-align: center;
  margin: 0 auto;
}

@media screen and (max-width: 680px) {
  .carousel__container {
    display: none;
  }
}
