.offers__container {
  margin-top: 10rem;
  font-size: 40px;
  color: #f44336;
  padding-left: 5rem;
  padding-right: 5rem;
}
.offers__title {
  margin-bottom: 2rem;
}
.offers__option {
  width: calc(30rem + 2px);
  border: 1px lightgrey solid;
  border-radius: 10px;
  margin-right: 2.5rem;
  margin-left: 2.5rem;
  margin-bottom: 5rem;
  img {
    width: 30rem;
    height: 20rem;
    border-radius: 10px 10px 0 0;
  }
  a {
    color: white;
    background-color: #f44336;
    width: 100%;
    display: block;
    border-radius: 0 0 10px 10px;
    font-size: 30px;
    i {
      vertical-align: -3px;
    }
  }
  a:hover {
    text-decoration: none;
    background-color: #922720;
  }
}
// .offers__option:hover {
//   transform: scale(1.05);
// }
.offers__options {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  // margin: 0 0rem;
}
.option__title {
  font-size: 35px;
  margin-bottom: 1rem;
}
.option__description {
  font-size: 20px;
  color: black;
  padding: 0 1rem;
  padding-bottom: 1rem;
  min-height: 15rem;
}
.offers__description {
  color: black;
  font-size: 30px;
  margin-bottom: 2rem;
}

@media screen and (max-width: 1500px) {
  .offers__description {
    font-size: 20px;
  }
  .offers__option {
    width: calc(20rem + 2px);
    img {
      width: 20rem;
      height: 15rem;
      border-radius: 10px 10px 0 0;
    }
  }
  .option__description {
    min-height: 18rem;
  }
  .offers__option:hover {
    transform: none;
  }
  .offers__container {
    margin-top: 5rem;
    padding-left: 0rem;
    padding-right: 0rem;
  }
  .offers__title {
    font-size: 30px;
  }
  .offers__description {
    font-size: 20px;
  }
  .option__title {
    font-size: 30px;
  }
}
