.moving__box {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  img {
    width: 50%;
  }
  .moving__left {
    width: 50%;
    padding: 0 5%;
    text-align: left;
  }
  .moving__title {
    font-size: 30px;
    color: #f44336;
    font-weight: bold;
    margin-bottom: 2rem;
  }
}
.moving__container {
  a {
    display: block;
    text-align: center;
    background-color: #f44336;
    margin-top: 0rem;
    border-radius: 10px;
    padding: 0.2rem 0rem;
    color: white;
    font-size: 30px;
    width: 35%;
    margin: 0 auto;
    margin-top: 5rem;
  }
  a:hover {
    text-decoration: none;
    background-color: #922720;
    cursor: pointer;
  }
}
.moving__description {
  font-size: 20px;
  span {
    color: #f44336;
  }
}
.moving__page__title {
  color: #f44336;
  font-size: 40px;
  margin-bottom: 5rem;
}

@media screen and (max-width: 1500px) {
  .moving__page__title {
    font-size: 30px;
  }
  .moving__box {
    .moving__title {
      font-size: 25px;
    }
  }
  .moving__container {
    a {
      width: 60%;
      font-size: 25px;
    }
  }
}
@media screen and (max-width: 680px) {
  .moving__box {
    .moving__title {
      font-size: 25px;
    }
    .moving__left {
      width: 95%;
    }
    img {
      width: 100%;
    }
  }
  .moving__container {
    a {
      width: 95%;
    }
  }
  .adapt {
    margin-bottom: 5rem;
  }
}
